<template>
  <v-dialog v-model="show" width="600">
    <v-card>

      <v-card-title>
        Выберите версию для копирования
      </v-card-title>
      <v-divider />
      
      <v-card-subtitle>
        <VersionSelection
          :filter="filter"
          :selected="selected"
          @selectVersion="onVersionSelected"
        ></VersionSelection>
      </v-card-subtitle>
      <v-card-text class="pt-4 body-1">
        Вы действительно хотите
        скопировать версию № {{ version }} ?
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-btn
          @click="show = false"
          color="red"
          outlined
        >
          Отмена
        </v-btn>
        <v-spacer />

        <v-btn
          @click="checkClosedRoute"
          :loading="loading"
          color="primary"
          dark
        >
          Копировать
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
  import VersionSelection from '@/components/actions/RoutesSettings/VersionSelection';
  
  export default {
    name: 'CopyRouteVersion',
    components: {
      VersionSelection,
    },
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    name: "CopyRouteVersion",
    data: function() {
      return {
        show: true,
        date: new Date().toISOString().substr(0, 10),
        date_menu: false,
        selected: null,
        version: null,
        loading: false,
        isClosedRoute: false,
        filter: '',
      };
    },
    created() {
      this.selected = this.collection.master.selected;

      if (!this.selected){
        this.show = false;
        jet.msg({
          text:'Необходимо выбрать запись!',
          color:'warning'
        });
        return;
      }
      
      this.version = this.selected.vcroutesVersion;
      this.filter = `eq(field(".verID"),param("${this.selected.vcroutesVerid}","id"))`;      
    },
    methods: {
      async copyRouteVersion() {
        const currentDate = new Date();

        let optionsQuery = {
          type: 'query', 
          query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.copyRouteWeb',
          params: {
            in_routeID: (this.selected.vcroutesId),
            in_tenantID: (this.selected._sec_attrsTenantid),
            in_userID: (this.selected._sec_attrsUserid),
            in_date: currentDate,
            in_newrouteID: '00000000-0000-0000-0000-000000000000',
            in_type: 3,
          }
        }

        try{
          this.loading = true;
          const resp = await jet.http.post(optionsQuery);
          if (resp.error){
            throw resp;
          }
          jet.msg({
            text: `Копия версии ${this.version} маршрута 
              № ${this.selected.vcroutesRoutecode} 
              ${this.selected.vcroutesRoutename} успешно создана!`
          });
          this.show = false;
          this.loading = false;
          this.collection.refresh(this.selected.vcroutesId);
        } catch(e) {
          jet.msg({
            text:'Не удалось скопировать версию маршрута.', 
            color:'warning'
          });
          console.error('CopyRouteVersion: copyRouteVersion()', e);
        }
      },
      async checkClosedRoute() {
        let routesCount;
        const verID = this.selected.vcroutesVerid;

        let optionsQuery = {
          type: 'core-read',
          query: `sin2:/v:acd2f542-598c-4540-b46c-03375098d467/?filter=and(eq(field(".verID"),param("${verID}","id")),isnull(field(".endDt")))&fields=.version,.ID`
        };

        try{
          this.loading = true;

          const resp = await jet.http.post(optionsQuery);
          if (resp.error){
            throw resp;
          }
          routesCount = resp.result.data.length;
        } catch(e) {
          console.error('CopyRouteVersion: checkClosedRoute()', e);
        }

        if(routesCount) {
          this.copyRouteVersion();
        } else {
          this.show = false;

          jet.msg({
            text:'Необходимо создать новый маршрут.', 
            color:'warning'
          });
          return;
        }
      },
      onVersionSelected(item) {
        this.selected = item;
        this.version = this.selected.vcroutesVersion;
      },
    },
  }
</script>